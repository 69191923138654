.react-colorful {
    width: 100px !important;
    height: 100px !important;
    border-radius: 4px !important;
}

.react-colorful__pointer {
    width: 16px !important;
    height: 16px !important;
}

.react-colorful__hue {
    height: 16px !important;
}