.leaflet-control-lasso {
    height: 30px;
    width: 30px;
    background-image: url("../../../assets/icons/lasso.svg") !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px 16px !important;
    background-color: #F50 !important;
    border: none !important;
    border-radius: 4px !important;
}

@media (max-width: 1024px) {
    .leaflet-control-lasso {
        height: 40px !important;
        width: 40px !important;
    }
}

.leaflet-control-lasso:hover {
    background-color: #D64700 !important;
    box-shadow: 1px 1px 4px 0 #B03B00 inset;
}

.lasso-popup {
    background-color: transparent;
    box-shadow: none;
    border: none;
    pointer-events: auto !important;
}

.lasso-popup:before {
    border: none;
}

.leaflet-lasso-active .leaflet-control-lasso {
    background-color: #D64700 !important;
    box-shadow: 1px 1px 4px 0 #B03B00 inset;
}

.leaflet-lasso-close .leaflet-control-lasso {
    background-image: url("../../../assets/icons/close.svg") !important;
}

.leaflet-lasso-active .leaflet-control-lasso {
    background-image: url("../../../assets/icons/close.svg") !important;
}

.leaflet-bar {
    border: none !important;
}

@media (max-width: 1024px) {
    .leaflet-control-lasso {
        height: 40px;
        width: 40px;
    }
}