@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .square {
        aspect-ratio: 1 / 1;
    }

    .center {
        @apply flex items-center justify-center
    }

    .my-map {
        @apply w-full h-full
    }

    .transition-full {
        transition: all 0.3s linear;
    }

    .transition-opacity {
        transition: opacity 0.3s linear;
    }

    .stroke-path-white path {
        @apply stroke-white
    }

    .stroke-path-grey-100 path {
        @apply stroke-grey-100
    }

    .stroke-path-grey-2600 path {
        @apply stroke-grey-2600
    }

    .stroke-path-yellow-600 path {
        @apply stroke-yellow-600
    }


    .stroke-path-grey path {
        @apply stroke-white-200
    }

    .stroke-path-green path {
        @apply stroke-green-100
    }

    .btn {
        @apply flex justify-center rounded-full transition-full font-gilroy-600 gap-2;
    }

    /*btn-turquoise*/
    .btn-turquoise {
        @apply bg-turquoise-100 text-white
    }

    .btn-turquoise:active {
        @apply text-blue-100 bg-white;
    }

    .btn-turquoise:disabled {
        @apply text-blue-400 bg-white-100 border-2 border-blue-400;
    }

    .btn-turquoise:hover:not(:disabled):not(:active) {
        @apply text-blue-100 bg-gradient-turquoise-200 shadow-turquoise-100;
    }

    .path {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        animation: dash 1.5s ease-in-out infinite;
        stroke-linecap: round;
    }

    .tooltip-left {
        transform: translate(-50%, -50%)
    }

    .font-gothampro-400 {
        font-family: 'GothamPro Regular', sans-serif;
        font-weight: 400;
    }

    .font-gothampro-500 {
        font-family: 'GothamPro Medium', sans-serif;
        font-weight: 500;
    }

    .font-gothampro-700 {
        font-family: 'GothamPro Bold', sans-serif;
        font-weight: 700;
    }

    .font-gothampro-900 {
        font-family: 'GothamPro Black', sans-serif;
        font-weight: 900;
    }
}


@font-face {
    font-family: 'GothamPro Regular';
    src: url('./assets/fonts/gothampro.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'GothamPro Medium';
    src: url('./assets/fonts/gothampro_medium.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'GothamPro Bold';
    src: url('./assets/fonts/gothampro_bold.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: 'GothamPro Black';
    src: url('./assets/fonts/gothampro_black.ttf') format('truetype');
    font-weight: 900;
    font-display: swap;
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

html,
body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: #F4F4F4;
}

body {
    background-color: #F4F4F4;
}


*::-webkit-scrollbar {
    height: 4px;
    width: 4px;
}

*::-webkit-scrollbar-track {
    background: #EAECEC;
    border-radius: 30px;
}

*::-webkit-scrollbar-thumb {
    background-color: #909090;
    border: 4px solid #909090;
    border-radius: 30px;
}

#screenPage {
    position: absolute;
    z-index: 99999;
    top: 4%;
    left: 4%;
    box-shadow: 2px 2px 4px 0 rgba(37, 74, 99, 0.25);
}

#screenIcon {
    position: absolute;
    z-index: 2300;
    top: 206px;
    right: 0;
    cursor: pointer;
    width: 30px;
    height: 30px;
}

#screenPage .active .check {
    opacity: 1;
}

#screenPage .active .icon {
    background-color: #0009FF;
}

#screenPage .active {
    background-color: #E7EFFF;
}

@media only screen and (max-width: 1024px) {
    #screenIcon {
        position: absolute;
        z-index: 2200;
        left: 16px;
        top: 406px;
        width: 40px;
        height: 40px;
    }
}

.dark *::-webkit-scrollbar-track {
    background: #8097AB;
}

.dark *::-webkit-scrollbar-thumb {
    background-color: #284E72;
    border: 4px solid #284E72;
}

.dark body {
    background-color: #0D2C49;
}

a {
    color: inherit;
    text-decoration: none;
}

input:focus {
    outline: none;
}

textarea:focus {
    outline: none;
}

#root {
    width: 100%;
    height: 100%;
    cursor: default;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

* {
    box-sizing: border-box;
}

.leaflet-control {
    margin-top: 60px !important;
}

.leaflet-control-attribution {
    display: none !important;
}

.leaflet-popup-content-wrapper {
    background-color: rgba(64, 64, 64, 0.90);
}

.leaflet-popup-tip {
    background-color: rgba(64, 64, 64, 0.90);
}

.my-tooltip {
    background-color: rgba(64, 64, 64, 0.90)
}

.dark .leaflet-popup-content-wrapper {
    background-color: rgba(13, 44, 73, 0.80);
}

.dark .leaflet-popup-tip {
    background-color: rgba(13, 44, 73, 0.80);
}

.dark .my-tooltip {
    background-color: rgba(13, 44, 73, 0.80);
}

.hover-z-index {
    z-index: 1800 !important;
}

.hover-z-index:hover {
    z-index: 1900 !important;
}

.block-status-charts-canvas-wrapper {
    width: 46px;
    height: 46px;
    position: absolute;
    z-index: 110;
    background: #FFFFFF;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.block-status-charts-canvas {
    width: 84px;
    height: 42px;
}

@media (max-width: 1024px) {
    .block-status-charts-canvas-wrapper {
        width: 44px;
        height: 44px;
    }

    .block-status-harts-canvas {
        width: 72px;
        height: 36px;
    }
}

.status-charts-canvas {
    width: 118px;
    height: 58px;
}

@media (max-width: 1024px) {
    .status-harts-canvas {
        width: 74px;
        height: 38px;
    }
}

.favorite-popup {
    padding: 0;
    background-color: transparent;
    border: none;
    margin-top: -44px;
    pointer-events: auto;
    z-index: 10;
}

.favorite-popup:before {
    border: none;
}