.leaflet-ruler {
    height: 30px;
    width: 30px;
    background-image: url("../../../assets/icons/kit/measure.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px 16px !important;
    background-color: #F50;
    border: none !important;
}


.leaflet-ruler-clicked {
    background-image: url("../../../assets/icons/close.svg");
}

.ruler-close {
    width: 12px;
    height: 12px;
    background-image: url("../../../assets/icons/close.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px 12px !important;
    cursor: pointer;
}

.leaflet-ruler:hover {
    background-color: #D64700;
    box-shadow: 1px 1px 4px 0 #B03B00 inset;
}

.leaflet-ruler-clicked {
    height: 30px;
    width: 30px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #D64700;
    box-shadow: 1px 1px 4px 0 #B03B00 inset;
}

@media (max-width: 1024px) {
    .leaflet-ruler {
        height: 40px;
        width: 40px;
    }

    .leaflet-ruler-clicked {
        height: 40px;
        width: 40px;
    }
}

.leaflet-control {
    cursor: pointer;
}

.result-tooltip {
    background-color: #FF0000;
    font-size: 10px;
    line-height: 12px;
    padding: 4px 6px;
    border-radius: 32px;
    color: white;
    font-family: 'GothamPro Medium', sans-serif;
    border: none;
    user-select: auto !important;
}

.result-tooltip:before {
    display: none;
}

.moving-tooltip {
    background-color: #FF0000;
    font-size: 10px;
    line-height: 12px;
    padding: 4px 6px;
    border-radius: 32px;
    color: white;
    top: 39px;
    font-family: 'GothamPro Medium', sans-serif;
    border: none;
}

.result-tooltip + .leaflet-tooltip-left {
    left: -12px;
}

.moving-tooltip + .leaflet-tooltip-left {
    left: -12px;
}

.result-tooltip + .leaflet-tooltip-right {
    left: 12px;
}

.moving-tooltip + .leaflet-tooltip-right {
    left: 12px;
}

.result-tooltip + .leaflet-tooltip-left:before {
    display: none;
}


.moving-tooltip + .leaflet-tooltip-left:before {
    display: none;
}

.result-tooltip + .leaflet-tooltip-right:before {
    display: none;
}

.moving-tooltip + .leaflet-tooltip-right:before {
    display: none;
}

.plus-length {
    padding-left: 45px;
}
