.swiper-button-disabled {
    opacity: 0;
}

.navigate-slider .swiper-slide {
    opacity: 0.5;
    cursor: pointer;
}

.navigate-slider .swiper-slide-thumb-active {
    opacity: 1;
}

.navigate-slider .swiper-slide:hover {
    opacity: 1;
}